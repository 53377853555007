import React, { useContext } from "react";
import { Outlet, Navigate, useParams } from "react-router-dom";

import { INTERN_ACCOUNT_LOGIN } from "./routes";
import AuthContext from "./context/auth-context";

const PrivateRoutes = () => {
    const { id } = useParams();
    let { user } = useContext(AuthContext);

    return (
        user ? <Outlet /> : <Navigate to={INTERN_ACCOUNT_LOGIN + '/' + id} />
    );
}

export default PrivateRoutes;