import React from 'react';
import PropTypes from 'prop-types';

import HEYGHeaderMinimal from '../HEYGHeaderMinimal/HEYGHeaderMinimal';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * @returns {JSX.Element} - The rendered page layout 1 component.
 */
function PageLayout2({ banner, children }) {
    return (
        <div className="bg-HEYG-light-gray">
            <HEYGHeaderMinimal />
            {banner}
            <div className='max-w-[990px] mx-auto sm:px-6 lg:px-8'>
                {children}
            </div>
        </div>
    );
}

PageLayout2.propTypes = {
    banner: PropTypes.element,
    children: PropTypes.element.isRequired
};

export default PageLayout2;
