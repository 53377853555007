import React from 'react';

import { INTERN_CONFIGURATOR_START } from '../../../../@global/routes';
import HEYGHeaderMinimal from '../../../../@ui-common/HEYGHeaderMinimal/HEYGHeaderMinimal';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
function PageNotFound() {
  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <div>
      <HEYGHeaderMinimal />
      <div
        className="max-w-[990px] min-h-screen mx-auto flex flex-col justify-center items-center text-center py-8 px-4"
      >
        <h1>Hoppla! Seite nicht gefunden</h1>
        <h2 className='mb-xlarge'>Wir können die gesuchte Seite leider nicht finden.</h2>
        <a
          href={INTERN_CONFIGURATOR_START}
          type="button"
          className="heyg-btn-primary heyg-btn--min-space"
        >
          Zurück zum Anfang »
        </a>
      </div>
    </div>
  );
}

export default PageNotFound;
