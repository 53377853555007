import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import AuthContext from '../../../../@global/context/auth-context';
import HEYGLoader from '../../../../@ui-common/HEYGLoader/HEYGLoader';

const PageLogin = () => {
    const { id } = useParams();
    let { loginUser } = useContext(AuthContext);

    useEffect(() => {
        loginUser(id);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <HEYGLoader />
        </div>
    )
}

export default PageLogin