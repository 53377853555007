import React from 'react';
import PropTypes from 'prop-types';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * @returns {JSX.Element} - The rendered section size M component.
 */
function SectionM({ title, actions, classname, children }) {
  const classes = `heyg-container ${classname}`;

  return (
    <div className='h-full'>
      {
        (title || actions) && <div className='flex justify-between items-center gap-4 px-4 sm:px-8 mb-[1rem]'>
          {title && <p className='text-[1.25rem] sm:text-[1.625rem] font-bold'>{title}</p>}
          {actions && <div>{actions}</div>}
        </div>
      }
      <div className={classes}>
        {children}
      </div>
    </div>
  );
}

SectionM.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.element,
  classname: PropTypes.string,
  children: PropTypes.element.isRequired
};

SectionM.defaultProps = {
  title: '',
  actions: undefined,
  classname: ''
}

export default SectionM;
