import React, { useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useParams } from 'react-router-dom';

import { INTERN_ACCOUNT_LOGIN } from '../../../../@global/routes';

import { redirectToStart } from '../../../../@global/redirection';
import Modal from '../../../../@ui-common/Modal/Modal';
import SectionM from '../../../../@ui-common/SectionM/SectionM';
import useJwtValidationMiddleware from '../../../../@global/hook/jwt-validation-hook';
import { servicePostUploadedImage$ } from '../../rest-services/lead-app-rest-service';
import HoverHint from '../../../../@ui-common/HoverHint/HoverHint';
import LeadAppNavigation from '../lead-app-navigation';
import AuthContext from '../../../../@global/context/auth-context';
import StatusContext from '../../../../@global/context/status-context';

const PageLeadAppImageUpload = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { success, resetSuccess, error, resetError } = useContext(StatusContext);
  const { isTokenExpired } = useContext(AuthContext);
  const [leadMeta, setLeadMeta] = useState({ deal: '' });
  const postUploadImages$ = useJwtValidationMiddleware(servicePostUploadedImage$);

  const retrieveLeadMeta = () => {
    const queryString = window.location.search;
    const decodedUrlParams = new URLSearchParams(queryString);
    const deal = decodedUrlParams.get('a');

    if (deal == null || deal === '') redirectToStart();

    return { deal: deal };
  };

  const [filesDropzone1, setFilesDropzone1] = useState([]);
  const [filesDropzone2, setFilesDropzone2] = useState([]);
  const [filesDropzone3, setFilesDropzone3] = useState([]);
  const [isAnyNotEmpty, setIsAnyNotEmpty] = useState(false);

  const onDropDropzone1 = (acceptedFiles) => {
    setFilesDropzone1([...filesDropzone1, ...acceptedFiles]);
  };

  const onDropDropzone2 = (acceptedFiles) => {
    setFilesDropzone2([...filesDropzone2, ...acceptedFiles]);
  };

  const onDropDropzone3 = (acceptedFiles) => {
    setFilesDropzone3([...filesDropzone3, ...acceptedFiles]);
  };

  const removeFile = (fileArray, setFileArray, index) => {
    const updatedFiles = [...fileArray];
    updatedFiles.splice(index, 1);
    setFileArray(updatedFiles);
  };

  const uploadFiles = async () => {
    const combinedFiles = [...filesDropzone1, ...filesDropzone2, ...filesDropzone3];

    const formData = new FormData();
    combinedFiles.forEach((file) => {
      formData.append(`files`, file);
    });

    await postUploadImages$(leadMeta.deal, formData);
  };

  const { getRootProps: getRootPropsDropzone1, getInputProps: getInputPropsDropzone1 } = useDropzone({
    onDrop: onDropDropzone1,
  });

  const { getRootProps: getRootPropsDropzone2, getInputProps: getInputPropsDropzone2 } = useDropzone({
    onDrop: onDropDropzone2,
  });

  const { getRootProps: getRootPropsDropzone3, getInputProps: getInputPropsDropzone3 } = useDropzone({
    onDrop: onDropDropzone3,
  });

  useEffect(() => {
    const leadMeta = retrieveLeadMeta();
    setLeadMeta(leadMeta);
  }, []);

  useEffect(() => {
    // Function to check if any of the state arrays is not empty
    const checkIfAnyNotEmpty = () => {
      if (
        filesDropzone1.length > 0 ||
        filesDropzone2.length > 0 ||
        filesDropzone3.length > 0
      ) {
        setIsAnyNotEmpty(true);
      } else {
        setIsAnyNotEmpty(false);
      }
    };

    // Call the function whenever any of the state arrays change
    checkIfAnyNotEmpty();
  }, [filesDropzone1, filesDropzone2, filesDropzone3]);

  const renderDropzones = () => {
    const dropzone = (files, setFiles, getRootProps, getInputProps, dropzoneTitle, dropzoneHelper, dropzoneImages, dropzoneIndex) => {
      const isFirstDropzone = dropzoneIndex === 1;

      return (
        <SectionM title={isFirstDropzone? 'Dachfotos hochladen' : ''} actions={isFirstDropzone ? renderInfoTooltip() : undefined}>
          <div>
            <div className='flex gap-2 items-center mb-[4px]'>
              <span className="flex items-center justify-center w-6 h-6 border-[2px] border-[#1a1a1a] rounded-full shrink-0">
                <strong>{dropzoneIndex}</strong>
              </span>
              <p className='text-[1.25rem] font-bold'>{dropzoneTitle}</p>
            </div>
            <p className='heyg-text-small mt-[1.5rem]'><strong className='text-[#FFC200]'>Bitte beachte:{' '}</strong>{dropzoneHelper}.</p>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-[2.5rem]'>
              <div>
                {dropzoneImages.map((image, index) => (
                  <img key={index} src={'/images/upload-templates/' + image} className='rounded-[14px] h-full w-full object-cover object-left-bottom sm:object-bottom mb-4'></img>
                ))}
              </div>
              <div {...getRootProps()} style={dropzoneStyles} className="flex flex-col justify-center items-center gap-4 bg-[#eff2f7] border-dashed border-2 p-4 mb-4 rounded-[14px] h-full hover:cursor-pointer hover:bg-[#f9f8f3]">
                <input {...getInputProps()} />
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
                <p>Dateien zum Hochladen hier ablegen</p>
              </div>
            </div>
            <div>
              {files.length > 0 && <p className='text-[16px] mb-4 mt-[2.5rem]'>Zum Hochladen ausgewählt:</p>}
              <ul className='[&>*]:border-b-[1px] [&>*:first-child]:pt-0'>
                {files.map((file, index) => (
                  <li key={index} className="flex items-center justify-between gap-4 mb-2 pt-2 pb-2 border-[#d0d4d8]">
                    <div className='flex gap-4 w-4/6 sm:w-full'>
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        className="h-[24px] w-[24px]"
                      />
                      <span className='text-[14px] truncate'>{file.name}</span>
                    </div>
                    <button
                      onClick={() => removeFile(files, setFiles, index)}
                      className="font-bold hover:underline"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </SectionM>
      )
    };

    return (
      <div>
        {dropzone(filesDropzone1, setFilesDropzone1, getRootPropsDropzone1, getInputPropsDropzone1, 'Gesamtfläche des Daches', 'Stelle sicher, dass der hintere Abschluss des Daches deutlich sichtbar ist. Verwende das Querformat für die Kamera und aktiviere, falls verfügbar, den Weitwinkelmodus, um die gesamte Dachfläche bestmöglich zu erfassen', ['IMG_example3_1.65560ac5998132.webp'], 1)}
        {dropzone(filesDropzone2, setFilesDropzone2, getRootPropsDropzone2, getInputPropsDropzone2, 'Gesamtfläche des Daches von der gegenüberliegenden Position', 'Stelle sicher, dass der hintere Abschluss des Daches deutlich sichtbar ist. Verwende das Querformat für die Kamera und aktiviere, falls verfügbar, den Weitwinkelmodus, um die gesamte Dachfläche bestmöglich zu erfassen', ['IMG_example2_2.65560aeaa28af4.webp'], 2)}
        {dropzone(filesDropzone3, setFilesDropzone3, getRootPropsDropzone3, getInputPropsDropzone3, '(Optional) Detailfotos', 'Sind sämtliche Dachränder, Dachaufbauten und Entwässerungen deutlich erkennbar? Sofern dies nicht der Fall ist, wäre es hilfreich, detaillierte Fotos von den bisher weniger sichtbaren Bereichen anzufertigen. Nur durch eine umfassende Dokumentation können wir eine optimale Planung für die Dachbegrünung gewährleisten', ['IMG_example1_0249.65560b4352a4e6.webp'], 3)}
      </div>
    );
  };

  const renderInfoTooltip = () => (
    <HoverHint chipText='Tipps' title='Tipps und sicheres Arbeiten'>
      <ul className='list-disc'>
        <li>Verwende die Funktion der Weitwinkelkamera, um Bilder von deinem Dach aufzunehmen</li>
        <li>Vermeide das Fotografieren gegen die Sonne</li>
        <li>Achte insbesondere an den Dachrändern auf deine Standsicherheit, um Stürze zu vermeiden</li>
        <li>Stelle sicher, dass Aufstiegshilfen wie Leitern sicher befestigt sind</li>
      </ul>
    </HoverHint>
  );


  return (
    <div className='mt-[1.5rem] sm:mt-10'>
      {renderDropzones()}
      <div className="text-center">
        <p className='heyg-title'>Alle Bilder ausgewählt?</p>
        <p>Dann kannst du die Fotos nun hochladen.</p>
      </div>
      <LeadAppNavigation
        middleChild={
          <p>Dachfotos hochladen</p>
        }
        actionRight={
          <div>
            {isAnyNotEmpty && (
              <button
                type="button"
                className="heyg-btn-primary heyg-btn--min-space"
                onClick={uploadFiles}
              >
                Hochladen »
              </button>
            )}
          </div>
        }
      />
      {isTokenExpired &&
        <Modal
          title='Sitzung abgelaufen'
          action1='Sitzung aktualisieren »'
          helper='Huch! Es sieht so aus, als ob deine Sitzung abgelaufen ist. Bitte aktualisiere deine Sitzung, um mit den Aktionen fortzufahren.'
          isOpen={isTokenExpired}
          handleClose={() => navigate(INTERN_ACCOUNT_LOGIN + '/' + id)}
        />
      }
      {success &&
        <Modal
          title='Hochladen Erfolgreich'
          helper='Deine Dachfotos wurden hochgeladen und stehen nun unserem Experten für die Planung zur Verfügung.'
          isOpen={success}
          handleClose={() => {resetSuccess(); navigate(-1);}}
        />
      }
      {error &&
        <Modal
          title='Fehler'
          helper='Ein unbekannter Fehler ist aufgetaucht. Bitte versuche es jetzt oder zu einem späteren Zeitpunkt erneut.'
          isOpen={error}
          handleClose={resetError}
        />
      }
    </div>
  );
};

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '14px',
  textAlign: 'center',
  padding: '20px',
};

export default PageLeadAppImageUpload;