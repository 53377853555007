import { fromFetch } from 'rxjs/internal/observable/dom/fetch';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
function restCallBuilderService(restCallBuilderCfg) {
  return fromFetch(`${process.env.REACT_APP_REST_DOMAIN}${restCallBuilderCfg.requestRoute}`, restCallBuilderCfg.init);
}

export default restCallBuilderService;
