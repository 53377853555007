import React, { useContext, lazy, Suspense } from 'react';
import { useParams } from "react-router-dom";

import { INTERN_CONFIGURATOR_START } from '../../../@global/routes';
import AuthContext from '../../../@global/context/auth-context';
import SectionM from '../../../@ui-common/SectionM/SectionM';
import HEYGFooterMinimal from '../../../@ui-common/HEYGFooterMinimal/HEYGFooterMinimal';

function PageLeadAppOverview() {
    const { id } = useParams();
    const { user } = useContext(AuthContext);

    const renderLeadDeals = (deals) => {
        const loadDealComponentByStatus = (deal) => {
            // debug because we dont have any deal stage template > 2.
            let stage = 'default';
            const dealStageAsNumber = Number(deal.stage);
            // stages implemented as status container.
            const prodStages = [1, 2, 16];
            // stages after offer was accepted.
            const closeStages = [15, 21, 36, 14, 37];
            if (!prodStages.includes(dealStageAsNumber)) {
                if (closeStages.includes(dealStageAsNumber)) {
                    stage = 'close';
                }
            } else {
                stage = deal.stage;
            }

            try {
                const DealComponent = lazy(() => import(`./lead-app-status/lead-app-status-${stage}`));
                return <DealComponent id={id} status={deal} />;
            } catch (error) {
                console.error('Error loading component');
                return null;
            }
        };

        const renderDeals = (deals) => {
            return (
                <div>
                    <div key={status}>
                        {deals.map((deal, index) => (
                            <Suspense key={deal.id} fallback={<div>Loading...</div>}>
                                <SectionM title={index === 0 ? 'Projekte' : ''} classname={index === 0 ? '' : '-mt-4'}>
                                    {loadDealComponentByStatus(deal)}
                                </SectionM>
                            </Suspense>
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div>
                {deals && Array.isArray(deals) ? (
                    renderDeals(deals)
                ) : (
                    <p>Keine Anfragen verfügbar</p>
                )}
            </div>
        );
    };

    const renderDropzoneNewConfiguration = () => (
        <SectionM classname='heyg-container--mb-0'>
            <div className="flex flex-col h-full">
                <div className='flex gap-2 items-center mb-[4px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-160h160v-80H520v-160h-80v160H280v80h160v160Zm40 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                    <p className='text-[#1a1a1a] text-[1.25rem] font-bold'>Neues Projekt</p>
                </div>
                <div className='h-full flex flex-col justify-between'>
                    <p className='text-[#1a1a1a] text-[1rem] mb-[1rem] mt-[1.5rem]'>Jetzt weitere Projekte zu deinem Profil hinzufügen.</p>
                    <a href={INTERN_CONFIGURATOR_START}>
                        <div className="flex items-center justify-center w-full">
                            <label className="flex flex-col items-center justify-center w-full h-48 sm:h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6 px-2 sm:px-0">
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Klicken zum Hinzufügen</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400 text-center"><span className="font-semibold">Gleiche E-Mail verwenden und automatisch mit Account verlinken.</span></p>
                                </div>
                            </label>
                        </div>
                    </a>
                </div>
            </div>
        </SectionM>
    );

    const renderCustomerHelp = () => (
        <SectionM classname='heyg-container--mb-0'>
            <div>
                <div className='flex gap-2 items-center mb-[4px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                    <p className='text-[#1a1a1a] text-[1.25rem] font-bold'>Hast du Fragen?</p>
                </div>
                <p className='text-[#1a1a1a] text-[1rem] mb-[1rem] mt-[1.5rem]'>Unsere Experten sind jederzeit verfügbar, um dir zu helfen.</p>
                <div className="flex flex-col items-center">
                    <img className="w-24 h-24 mb-3 rounded-full shadow-lg" src="https://uploads-ssl.webflow.com/64ca25c3d717007c658e276d/66a9136e0f910a85b76057b1_Portrait-Daniel-_1_%20(1).webp" alt="heygrün Experten Unterstützung" />
                    <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">Daniel Porzig</h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">Wie kann ich dir weiterhelfen?</span>
                    <div className="w-full flex flex-col md:flex-row gap-4 mt-4 md:mt-6">
                        <a href='mailto:anfrage@hey-gruen.de' className='heyg-btn-secondary block text-center'>E-Mail »</a>
                        <a href={process.env.REACT_APP_BOOKINGS} target='_blank' rel="noreferrer" className='heyg-btn-secondary block text-center'>Gespräch »</a>
                    </div>
                </div>
            </div>
        </SectionM>
    );

    return (
        <div className='mt-[1.5rem] sm:mt-[2.5rem]'>
            <div className='mb-8'>
                <div className='flex justify-center px-4 sm:px-8 text-center'>
                    <div>
                        <p className='heyg-title mb-2xsmall'>Hey {user.firstName}!</p>
                        <p>Danke, dass du dich für heygrün entschieden hast!</p>
                    </div>
                </div>
                <div className="mt-8 flex items-center p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300" role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                        Hier kannst du jederzeit benötigte Informationen und Dokumente für dein Projekt hochladen.
                    </div>
                </div>
            </div>
            {renderLeadDeals(user.deals)}
            <div>
                <p className='heyg-title px-4 sm:px-8 mb-[1rem]'>Service</p>
                <div className='flex flex-col sm:flex-row gap-4 mb-10'>
                    <div className='flex-1'>{renderDropzoneNewConfiguration()}</div>
                    <div className='flex-1'>{renderCustomerHelp()}</div>
                </div>
            </div>
            <HEYGFooterMinimal />
        </div>
    );
}

export default PageLeadAppOverview;