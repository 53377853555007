/** Application routes */
export const INTERN_NO_MATCH = '*';
export const INTERN_CONFIGURATOR_START = '/';
export const INTERN_CONFIGURATOR_PLANER = '/planer';
export const INTERN_CONFIGURATOR_CONFIRMATION = '/bestaetigung';
export const INTERN_ACCOUNT_BASE = '/app/:id';
export const INTERN_ACCOUNT_BASE_WITHOUT_ID = '/app';
export const INTERN_ACCOUNT_LOGIN = '/login';
export const INTERN_ACCOUNT_UPLOAD_PHOTO = '/upload/photos';
export const INTERN_ACCOUNT_UPLOAD_DOCUMENT = '/upload/documents';
/** Extern routes (website, social media) */
export const EXTERN_HOMEPAGE = 'https://www.hey-gruen.de';
export const EXTERN_IMPRINT = `${EXTERN_HOMEPAGE}/intern/impressum`;
export const EXTERN_DATA_PRIVACY = `${EXTERN_HOMEPAGE}/intern/datenschutz`;
export const EXTERN_HELP_AND_FAQ = `${EXTERN_HOMEPAGE}/leistungen/faq`;
export const EXTERN_SUBMIT_CONFIRMATION = `${EXTERN_HOMEPAGE}/leistungen/bestaetigung`;
export const EXTERN_FACEBOOK = 'https://www.facebook.com/heygruen/';
export const EXTERN_INSTAGRAM = 'https://www.instagram.com/heygruen/';
export const EXTERN_LINKEDIN = 'https://www.linkedin.com/company/heygruen/';
/** Rest */
export const REST_NEW_LEAD = '/submit/new-lead';
