import ValidationPatterns from '../enums/validation-patterns';

const submitFormElements = [
  {
    label: 'Adresse',
    inputs: [
      {
        id: 'submit-address-1',
        type: 'text',
        required: true,
        name: 'lead_street_and_number',
        label: 'Straße und Hausnummer',
        placeholder: 'Straße und Hausnummer',
        regValidation: ValidationPatterns.streetAndHouseNumber,
        errorMessage: 'Bitte gültige Straße und Hausnummer eingeben.',
      },
      {
        id: 'submit-address-2',
        type: 'number',
        required: true,
        name: 'lead_plz',
        label: 'PLZ',
        placeholder: 'PLZ',
        regValidation: ValidationPatterns.zip,
        errorMessage: 'Bitte gültige Postleitzahl eingeben.',
      },
      {
        id: 'submit-address-3',
        type: 'text',
        required: true,
        name: 'lead_district',
        label: 'Ort',
        placeholder: 'Ort',
        regValidation: ValidationPatterns.district,
        errorMessage: 'Bitte gültigen Ort eingeben.',
      },
    ],
  },
  {
    label: 'Kontakt',
    inputs: [
      {
        id: 'submit-contact-1',
        type: 'text',
        required: true,
        name: 'lead_first_name',
        label: 'Vorname',
        placeholder: 'Vorname',
        regValidation: ValidationPatterns.name,
        errorMessage: 'Namen sollten mit einem Großbuchstaben starten.',
      },
      {
        id: 'submit-contact-2',
        type: 'text',
        required: true,
        name: 'lead_second_name',
        label: 'Nachname',
        placeholder: 'Nachname',
        regValidation: ValidationPatterns.name,
        errorMessage: 'Namen sollten mit einem Großbuchstaben starten.',
      },
      {
        id: 'submit-contact-3',
        type: 'tel',
        required: true,
        name: 'lead_tel',
        label: 'Telefonnummer',
        placeholder: 'Telefonnummer für Rückfragen',
        regValidation: ValidationPatterns.phone,
        errorMessage: 'Bitte gültige Telefonnummer eingeben.',
      },
      {
        id: 'submit-contact-4',
        type: 'email',
        required: true,
        name: 'lead_mail',
        label: 'E-Mail',
        placeholder: 'Deine E-Mail Adresse',
        regValidation: ValidationPatterns.email,
        errorMessage: 'Bitte gültige Email eingeben.',
      },
    ],
  },
];

export default submitFormElements;
