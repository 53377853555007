import { useContext } from 'react';
import { catchError, switchMap, take, of, lastValueFrom } from 'rxjs';
import AuthContext from '../context/auth-context';
import StatusContext from '../context/status-context';
import LoadingContext from '../context/loading-context';

function useJwtValidationMiddleware(serviceFunction) {
    const { successSubject, errorSubject } = useContext(StatusContext);
    const { loadingSubject } = useContext(LoadingContext);
    const { tokenExpirationSubject } = useContext(AuthContext);

    return async (...args) => {
        loadingSubject.next(true);

        try {
            const response = await lastValueFrom(serviceFunction(...args).pipe(
                take(1),
                switchMap((response) => {
                    if (response.status === 401) {
                        tokenExpirationSubject.next();
                        return of();
                    } else if (!response.ok) {
                        throw new Error();
                    } else {
                        successSubject.next();
                        return of(response);
                    }
                    
                }),
                catchError(() => {
                    errorSubject.next(true);
                }),
            ));

            return response;
        } catch (error) {
            //todo;
        } finally {
            loadingSubject.next(false);
        }
    }
}

export default useJwtValidationMiddleware;