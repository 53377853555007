import React, { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { BehaviorSubject } from "rxjs";
import HEYGLoader from "../../@ui-common/HEYGLoader/HEYGLoader";

const LoadingContext = createContext();

export default LoadingContext;

export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingSubject] = useState(() => new BehaviorSubject(false));

    useEffect(() => {
        const subscription = loadingSubject.subscribe((v) => {
            setIsLoading(v);
        });

        return () => {
            subscription.unsubscribe();
        }
    }, [loadingSubject]);

    let contextData = {
        loading: isLoading,
        loadingSubject: loadingSubject
    }

    return (
        <LoadingContext.Provider value={contextData} >
            {isLoading && <HEYGLoader info={'Wird geladen...'}/>}
            {children}
        </LoadingContext.Provider>
    )
}

LoadingProvider.propTypes = {
    children: PropTypes.element,
};