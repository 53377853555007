import * as React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import HEYGHelpTooltip from '../HEYGHelpTooltip/HEYGHelpTooltip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
function HEYGAlertDialog({ title, isOpen, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className='p-8'>
        <div className='flex justify-between items-center gap-2'>
          <h1 className='heyg-text-base text-HEYG-error'>{title}</h1>
          <button onClick={handleClose}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.53317 25.3332L6.6665 23.4665L14.1332 15.9998L6.6665 8.53317L8.53317 6.6665L15.9998 14.1332L23.4665 6.6665L25.3332 8.53317L17.8665 15.9998L25.3332 23.4665L23.4665 25.3332L15.9998 17.8665L8.53317 25.3332Z" fill="black" />
            </svg>
          </button>
        </div>
        <br />
        <p className='heyg-text-s'>
          Es sieht so aus, als ob es derzeit einige Probleme bei der Übertragung deiner Anfrage gibt.
          Wir verstehen, wie frustrierend das sein kann, aber keine Sorge, wir sind hier, um zu helfen.
        </p>
        <br />
        <p><strong className='uppercase heyg-text-s'>Was kannst du im Falle einer Fehlermeldung tun?</strong></p>
        <ol className='list-disc heyg-text-s ml-[2rem] [&>li]:py-[0.5rem]'>
          <li><strong>Versuche es erneut:{' '}</strong>Manchmal sind vorübergehende Netzwerkprobleme oder Serverüberlastungen die Ursache für Fehler. Versuche deine Anfrage bitte in wenigen Augenblicken erneut.</li>
          <li><strong>Stabile Internetverbindung: {' '}</strong>Bitte stelle sicher, dass deine Internetverbindung stabil ist. Instabile Verbindungen können die ordnungsgemäße Übermittlung beeinträchtigen.</li>
          <li><strong>Kontaktiere unseren Support: {' '}</strong>Falls das Problem weiterhin besteht oder du zusätzliche Hilfe benötigst, zögere nicht, unseren Kundensupport zu kontaktieren.</li>
        </ol>
        <br />
        <p className='heyg-text text-center mt-[1rem] mb-[0.5rem]'><strong>Möchtest du uns kontaktieren?</strong></p>
        <div className='flex justify-center'>{<HEYGHelpTooltip />}</div>
      </div>
      <div className='flex justify-end p-[1.5rem]'>
        <button onClick={handleClose} className='heyg-btn-secondary-small'>Schließen</button>
      </div>
    </Dialog>
  );
}

HEYGAlertDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default HEYGAlertDialog;
