import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * @returns {JSX.Element} - The rendered tooltip component.
 */
function HoverHint({ chipText, title, headline, children }) {
    const [showTooltip, setShowTooltip] = useState(false);

    const TooltipContainer = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} open={showTooltip}
            onOpen={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f9f8f3',
            color: '#1a1a1a',
            maxWidth: 396,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
            borderRadius: '14px',
            padding: '1.5rem 1rem'
        },
    }));

    return (
        <div className='flex justify-center p-4'>
            <button onClick={() => setShowTooltip(!showTooltip)}>
                <TooltipContainer
                    title={
                        <React.Fragment>
                            <p className='heyg-headline'><strong>{title}</strong></p>
                            <p className='mb-6'>{headline}</p>
                            <div className='flex flex-col gap-4'>
                                {children}
                            </div>
                        </React.Fragment>

                    }
                >
                    <Chip
                        style={{ backgroundColor: '#f9f8f3' }}
                        label={chipText}
                        variant="outlined"
                        icon={<img src='/images/icons/contact_support.svg' width="18px" height="18px" alt="support and help icon" />}
                    />
                </TooltipContainer >
            </button >
        </div >
    );
}

HoverHint.propTypes = {
    chipText: PropTypes.string,
    title: PropTypes.string,
    headline: PropTypes.string,
    children: PropTypes.element.isRequired
};

HoverHint.defaultProps = {
    chipText: '',
    title: '',
    headline: ''
}

export default HoverHint;
