import * as React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
function Modal({ title, action1, helper, isOpen, handleClose, children }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const closeIconButton = () => (
    <button onClick={handleClose}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.53317 25.3332L6.6665 23.4665L14.1332 15.9998L6.6665 8.53317L8.53317 6.6665L15.9998 14.1332L23.4665 6.6665L25.3332 8.53317L17.8665 15.9998L25.3332 23.4665L23.4665 25.3332L15.9998 17.8665L8.53317 25.3332Z" fill="black" />
      </svg>
    </button>
  );

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className='p-8'>
        <div className='flex justify-between items-center gap-2 mb-4'>
          <h1>{title}</h1>
          {closeIconButton()}
        </div>
        <div>
          <p>{helper}</p>
          {children}
        </div>
      </div>
      {action1 &&
        <div className='flex justify-end p-[1.5rem]'>
          <button onClick={handleClose} className='heyg-btn-secondary'>{action1}</button>
        </div>
      }
    </Dialog>
  );
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  action1: PropTypes.string,
  helper: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.element
};

export default Modal;
