import { redirectToStart } from "./redirection";

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 *
 * Utility functions for encoding and decoding url query strings.
 */
export function encodeBase64(value) {
  return btoa(value);
}

export function decodeBase64(encodedValue) {
  return atob(encodedValue);
}

export function decodeFromUrlQueryString(encodedUrlQueryString) {
  try {
    const decodedQueryString = `?${decodeBase64(encodedUrlQueryString.slice(1))}`;

    return new URLSearchParams(decodedQueryString);
  } catch(e) {
    redirectToStart();
  }
}
