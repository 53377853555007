import {
  catchError, of, switchMap, take,
} from 'rxjs';

import servicePostFilledConfig$ from '../rest-services/configurator-rest-service';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
function postFilledConfig$(config = null) {
  try {
    return servicePostFilledConfig$(config).pipe(
      take(1),
      switchMap((response) => of(response)),
      catchError((err) => of({ status: 500, error: true, message: err })),
    );
  } catch (e) {
    return of({ status: 500, error: true, message: e.message });
  }
}

export default postFilledConfig$;
