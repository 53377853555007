import React, { useState } from 'react';

import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * @returns {JSX.Element} - The rendered help tooltip component.
 */
function HEYGHelpTooltip() {
  const [showTooltip, setShowTooltip] = useState(false);

  const ContactSupportTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f9f8f3',
      color: '#1a1a1a',
      maxWidth: 396,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      borderRadius: '14px',
      padding: '1.5rem 1rem'
    },
  }));

  return (
    <div className='flex justify-center'>
      <button onClick={() => setShowTooltip(!showTooltip)}>
        <ContactSupportTooltip
          title={
            <React.Fragment>
              <p className='heyg-headline'><strong>Schnelle Hilfe von unseren Experten</strong></p>
              <p className='mb-6'>Unser Team steht dir kompetent für deine Fragen zur Verfügung.</p>
              <div className='flex flex-col gap-4'>
                <a href='mailto:info@hey-gruen.de' className='heyg-btn-primary block text-center'>E-Mail »</a>
                <div>
                  <p className='mb-1'>Oder direkt persönlich:</p>
                  <a href={process.env.REACT_APP_BOOKINGS} target='_blank' rel="noreferrer" className='heyg-btn-secondary block text-center'>Experten Gespräch »</a>
                </div>
              </div>
            </React.Fragment>

          }
        >
          <Chip
            style={{ backgroundColor: '#f9f8f3' }}
            label="Hilfe und Kontakt"
            variant="outlined"
            icon={<img src='/images/icons/contact_support.svg' width="18px" height="18px" alt="support and help icon" />}
          />
        </ContactSupportTooltip >
      </button >
    </div >
  );
}

export default HEYGHelpTooltip;
