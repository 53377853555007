import React from 'react';
import PropTypes from 'prop-types';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * @returns {JSX.Element} - The loading spinner animation components.
 */
function HEYGLoader({ info }) {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="absolute w-full h-full backdrop-blur-sm bg-white/30 flex flex-col items-center justify-start z-50">
      <div className='sticky top-1/2 flex flex-col justify-center items-center'>
        <div className="relative w-[32px] h-[32px] animate-spin rounded-full corporate-gradient">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[24px] h-[24px] bg-gray-200 rounded-full border-2 border-white" />
        </div>
        <p className='heyg-text-base mt-[0.5rem] text-center px-2'>{info}</p>
      </div>
    </div>
  );
}

HEYGLoader.propTypes = {
  info: PropTypes.string
};

export default HEYGLoader;
