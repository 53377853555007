import React, { useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useParams } from 'react-router-dom';

import { INTERN_ACCOUNT_LOGIN } from '../../../../@global/routes';

import { redirectToStart } from '../../../../@global/redirection';
import Modal from '../../../../@ui-common/Modal/Modal';
import SectionM from '../../../../@ui-common/SectionM/SectionM';
import useJwtValidationMiddleware from '../../../../@global/hook/jwt-validation-hook';
import { servicePostUploadedDocuments$ } from '../../rest-services/lead-app-rest-service';
import HoverHint from '../../../../@ui-common/HoverHint/HoverHint';
import LeadAppNavigation from '../lead-app-navigation';
import AuthContext from '../../../../@global/context/auth-context';
import StatusContext from '../../../../@global/context/status-context';

const PageLeadAppDocumentUpload = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { success, resetSuccess, error, resetError } = useContext(StatusContext);
    const { isTokenExpired } = useContext(AuthContext);
    const [leadMeta, setLeadMeta] = useState({ deal: '' });
    const postUploadedDocuments$ = useJwtValidationMiddleware(servicePostUploadedDocuments$);

    const retrieveLeadMeta = () => {
        const queryString = window.location.search;
        const decodedUrlParams = new URLSearchParams(queryString);
        const deal = decodedUrlParams.get('a');
        // redirect to start possible change to app overview.
        if (deal == null || deal === '') redirectToStart();

        return { deal: deal };
    };

    const [filesDropzone1, setFilesDropzone1] = useState([]);
    const [isAnyNotEmpty, setIsAnyNotEmpty] = useState(false);

    const onDropDropzone1 = (acceptedFiles) => {
        setFilesDropzone1([...filesDropzone1, ...acceptedFiles]);
    };

    const removeFile = (fileArray, setFileArray, index) => {
        const updatedFiles = [...fileArray];
        updatedFiles.splice(index, 1);
        setFileArray(updatedFiles);
    };

    const uploadFiles = async () => {
        const combinedFiles = [...filesDropzone1];

        const formData = new FormData();
        combinedFiles.forEach((file) => {
            formData.append(`files`, file);
        });

        await postUploadedDocuments$(leadMeta.deal, formData);
    };

    const { getRootProps: getRootPropsDropzone1, getInputProps: getInputPropsDropzone1 } = useDropzone({
        onDrop: onDropDropzone1
    });

    useEffect(() => {
        const leadMeta = retrieveLeadMeta();
        setLeadMeta(leadMeta);
    }, []);

    useEffect(() => {
        const checkIfAnyNotEmpty = () => {
            if (filesDropzone1.length > 0) {
                setIsAnyNotEmpty(true);
            } else {
                setIsAnyNotEmpty(false);
            }
        };

        // Call the function whenever any of the state arrays change
        checkIfAnyNotEmpty();
    }, [filesDropzone1]);

    const renderDropzones = () => {
        const dropzone = (files, setFiles, getRootProps, getInputProps, dropzoneTitle, dropzoneHelper) => {
            return (
                <SectionM title='Dokumente hochladen' actions={renderInfoTooltip()}>
                    <div>
                        <div className='flex gap-2 items-center mb-[4px]'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-168l64 64 56-56-160-160-160 160 56 56 64-64v168ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z" /></svg>
                            <p className='heyg-title-small'>{dropzoneTitle}</p>
                        </div>
                        <p className='heyg-text-small mb-[2.5rem]'>{dropzoneHelper}.</p>
                        <div {...getRootProps()} style={dropzoneStyles} className="flex flex-col justify-center items-center gap-4 border-dashed bg-[#eff2f7] border-2 p-4 mb-4 rounded-[14px] min-h-[12rem] h-full hover:cursor-pointer hover:bg-[#f9f8f3]">
                            <input {...getInputProps()} />
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
                            <p>Dateien zum Hochladen hier ablegen</p>
                        </div>
                        <div>
                            {files.length > 0 && <p className='text-[16px] mb-4 mt-[2.5rem]'>Zum hochladen ausgewählt:</p>}
                            <ul className='[&>*]:border-b-[1px] [&>*:first-child]:pt-0'>
                                {files.map((file, index) => (
                                    <li key={index} className="flex items-center justify-between gap-4 mb-2 pt-2 pb-2 border-[#d0d4d8]">
                                        <div className='flex gap-4 w-4/6 sm:w-full'>
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={file.name}
                                                className="h-[24px] w-[24px]"
                                            />
                                            <span className='text-[14px] truncate'>{file.name}</span>
                                        </div>
                                        <button
                                            onClick={() => removeFile(files, setFiles, index)}
                                            className="font-bold hover:underline"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </SectionM>
            )
        };
        return (
            <div>
                {dropzone(filesDropzone1, setFilesDropzone1, getRootPropsDropzone1, getInputPropsDropzone1, 'Dokumente einfach auswählen & hochladen', 'Hier kannst du relevante Dokumente zu deiner Anfrage hochladen (z.B. Bauunterlagen oder Grundriss). Achte darauf, dass alle Dachränder (oder Dachseiten) mit einer Längenangabe versehen sind')}
            </div>
        );
    };

    const renderInfoTooltip = () => (
        <HoverHint chipText='Tipps' title='Weitere Informationen'>
            <ul className='list-disc'>
                <li>Die Möglichkeit zum Hochladen von Dokumenten besteht für alle üblichen Dateiformate.</li>
                <li>Die Bereitstellung von Bauunterlagen oder Grundrissen ermöglicht unseren Experten eine präzisere und effizientere Planung.</li>
                <li>Es besteht die Möglichkeit, zu jeder Zeit weitere Dokumente im Kontext deiner Anfrage hochzuladen.</li>
            </ul>
        </HoverHint>
    );


    return (
        <div className='mt-[1.5rem] sm:mt-10'>
            {renderDropzones()}
            <div className="text-center">
                <p className='heyg-title'>Alle Dokumente ausgewählt?</p>
                <p>Dann kannst du die Dokumente nun hochladen.</p>
            </div>
            <LeadAppNavigation
                middleChild={
                    <p>Dokumente hochladen</p>
                }
                actionRight={
                    <div>
                        {isAnyNotEmpty && (
                            <button
                                type="button"
                                className="heyg-btn-primary heyg-btn--min-space"
                                onClick={uploadFiles}
                            >
                                Hochladen »
                            </button>
                        )}
                    </div>
                }
            />
            {isTokenExpired &&
                <Modal
                    title='Sitzung abgelaufen'
                    action1='Sitzung aktualisieren »'
                    helper='Huch! Es sieht so aus, als ob deine Sitzung abgelaufen ist. Bitte aktualisiere deine Sitzung, um mit den Aktionen fortzufahren.'
                    isOpen={isTokenExpired}
                    handleClose={() => navigate(INTERN_ACCOUNT_LOGIN + '/' + id)}
                />
            }
            {success &&
                <Modal
                    title='Hochladen Erfolgreich'
                    helper='Deine Dokumente wurden hochgeladen und stehen nun unserem Experten für die Planung zur Verfügung. Falls du noch nicht alle erforderlichen Dachfotos hochgeladen hast, kehre zurück, um diese hochzuladen'
                    isOpen={success}
                    handleClose={() => {resetSuccess(); navigate(-1);}}
                />
            }
            {error &&
                <Modal
                    title='Fehler'
                    helper='Ein unbekannter Fehler ist aufgetaucht. Bitte versuche es jetzt oder zu einem späteren Zeitpunkt erneut.'
                    isOpen={error}
                    handleClose={resetError}
                />
            }
        </div>
    );
};

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '14px',
    textAlign: 'center',
    padding: '20px',
};

export default PageLeadAppDocumentUpload;