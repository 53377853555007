import React from 'react';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
function ConfiguratorBenefitDisplay() {

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div
      id="benefit-display"
      className="hidden sm:block relative corporate-gradient p-4"
    >
      <div
        className="max-w-[990px] mx-auto flex flex-col md:flex-row md:justify-center md:items-center gap-2 md:gap-8 text-white text-[16px] px-14 sm:px-6 lg:px-8"
      >
        <div className="w-full md:w-1/3 flex justify-start md:justify-center items-center gap-4">
          <img src='/images/icons/money-pig.svg' width="18px" height="18px" alt="Money Pig Icon" />
          <span>100% kostenfreies Angebot</span>
        </div>
        <div className="w-full md:w-1/3 flex justify-start md:justify-center items-center gap-4">
          <img src='/images/icons/happiness.svg' width="18px" height="18px" alt="Happiness Icon" />
          <span>Zufriedenheitsgarantie</span>
        </div>
        <div className="w-full md:w-1/3 flex justify-start md:justify-center items-center gap-4">
          <img src='/images/icons/tool.svg' width="18px" height="18px" alt="Tool Icon" />
          <span>Beratung bis zur Pflege</span>
        </div>
      </div>
    </div>
  );
}

export default ConfiguratorBenefitDisplay;
