/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * @type {{zip: string, phone: string, district: string, name: string,
 * streetAndHouseNumber: string, email: string}}
 */
const ValidationPatterns = {
  streetAndHouseNumber: '',
  zip: '^[0-9]{5}$',
  district: '',
  name: '^(?!.*[\\s-]{2})[A-ZÄÖÜ][A-Za-zÄÖÜäöüß\\s-]+$',
  phone: '^(?:(?:\\+|00)[49]([-.\\s]?)|\\()?([0-9]{3})\\1?([0-9]{3})\\1?([0-9]{4})(?:\\)?|\\s?(?:-?\\s?\\d{1,5})?)$',
  email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
};

Object.freeze(ValidationPatterns);

export default ValidationPatterns;
