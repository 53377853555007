import React from 'react';
import { INTERN_CONFIGURATOR_START } from '../../@global/routes';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * @returns {JSX.Element} - The rendered minimal Header components.
 */
function HEYGHeaderMinimal() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <nav className="bg-white border-b border-gray-100">
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
        <a href={INTERN_CONFIGURATOR_START}>
              <img
                src='/images/brand.svg'
                width="318"
                height="98"
                className="block h-[24px] w-auto"
                alt="heygrün logo"
              />
            </a>
        </div>
      </div>
    </nav>
  );
}

export default HEYGHeaderMinimal;
