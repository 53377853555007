import React, { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Subject } from "rxjs";

const StatusContext = createContext();

export default StatusContext;

export const StatusProvider = ({ children }) => {
    const [hasSuccess, setHasSuccess] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [successSubject] = useState(() => new Subject());
    const [errorSubject] = useState(() => new Subject());

    useEffect(() => {
        const subscription1 = successSubject.subscribe(() => {
            setHasSuccess(true);
        });

        const subscription2 = errorSubject.subscribe(() => {
            setHasError(true);
        });

        return () => {
            subscription1.unsubscribe();
            subscription2.unsubscribe();
        }
    }, [successSubject, errorSubject]);

    const resetHasSuccess = () => {
        setHasSuccess(false);
    }

    const resetHasError = () => {
        setHasError(false);
    }

    let contextData = {
        success: hasSuccess,
        error: hasError,
        resetSuccess: resetHasSuccess,
        resetError: resetHasError,
        successSubject: successSubject,
        errorSubject: errorSubject
    }

    return (
        <StatusContext.Provider value={contextData} >
            {children}
        </StatusContext.Provider>
    )
}

StatusProvider.propTypes = {
    children: PropTypes.element,
};