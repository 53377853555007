exports.CHOICE_STEP = [
  {
    column_name: 'roof_age',
    name: 'Gebäudealter',
    question: 'Wie alt ist deine Dachabdichtung?',
    helper: 'Falls die Dachabdichtung bisher nicht erneuert wurde, gib bitte das Dachalter an',
    options: [
      {
        id: 1,
        type: 'select',
        label: 'im Bau',
        value: 'im Bau',
        ico: 'roof-age/construction-32x32.svg',
      },
      {
        id: 2,
        type: 'select',
        label: '0-5 Jahre',
        value: '0-5',
        ico: 'roof-age/new-32x32.svg',
      },
      {
        id: 3,
        type: 'select',
        label: '6-14 Jahre',
        value: '6-14',
        ico: 'roof-age/mid-32x32.svg',
      },
      {
        id: 4,
        type: 'select',
        label: '15 Jahre und mehr',
        value: '15+',
        ico: 'roof-age/old-32x32.svg',
      },
      {
        id: 5,
        type: 'select',
        label: 'Ich weiß es nicht',
        value: 'Unbekannt',
        ico: 'question-mark-32x32.svg',
      },
    ],
    isConditional: false,
    hasConditionalChild: [],
  },
  {
    column_name: 'has_roof_load',
    name: 'Dachauflast',
    question: 'Liegt Kies oder andere schwere Auflasten (z.B. Betonplatten) auf dem Dach',
    helper: 'Sollte sich Kies oder andere schwere Auflasten auf dem Dach befinden, können unsere Experten die Eignung deines Daches schneller beurteilen',
    options: [
      {
        id: 1,
        type: 'select',
        label: 'Ja',
        value: 'ja',
        ico: 'has-roof-load/yes-32x32.svg',
      },
      {
        id: 2,
        type: 'select',
        label: 'Nein',
        value: 'nein',
        ico: 'has-roof-load/no-32x32.svg',
      },
    ],
    hasConditionalChild: [
      {
        column_name: 'roof_type',
        name: 'Unterkonstruktion',
        question: 'Wie ist deine Dachunterkonstruktion',
        helper: 'Auf Basis dessen können unsere Experten erste Rückschlüsse auf die Tragfähigkeit ziehen. Meist erkennst du die Art der Dachkonstruktion, wenn du von unten auf dein Dach schaust',
        type: 'select',
        options: [
          {
            id: 1,
            type: 'select',
            label: 'Beton',
            value: 'Beton',
            ico: null,
          },
          {
            id: 2,
            type: 'select',
            label: 'Holz',
            value: 'Holz',
            ico: null,
          },
          {
            id: 3,
            type: 'select',
            label: 'Stahl',
            value: 'Stahl',
            ico: null,
          },
          {
            id: 4,
            type: 'select',
            label: 'Ich weiß es nicht',
            value: 'Keine Ahnung',
            ico: 'question-mark-32x32.svg',
          },
        ],
        isConditional: true,
        showOnValue: 'nein',
        hasConditionalChild: [
          {
            column_name: 'roof_wood',
            name: 'Alter',
            question: 'Ist dein Gebäude bereits älter als 50 (fünfzig) Jahre',
            helper: 'Speziell bei älteren Holzkonstruktionen kann sich die Tragfähigkeit ändern und sollte individuell geprüft werden',
            type: 'select',
            options: [
              {
                id: 1,
                type: 'select',
                label: 'Ja',
                value: 'ja',
                ico: null,
              },
              {
                id: 2,
                type: 'select',
                label: 'Nein',
                value: 'nein',
                ico: null,
              },
              {
                id: 3,
                type: 'select',
                label: 'Ich weiß es nicht',
                value: 'Keine Ahnung',
                ico: 'question-mark-32x32.svg',
              },
            ],
            isConditional: true,
            showOnValue: 'Holz',
            hasConditionalChild: [],
          },
        ],
      },
    ],
  },
];
