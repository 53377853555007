import restCallBuilderService from '../../../@global/rest-call-builder/rest-call-builder.service';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
function servicePostUploadedImage$(deal, files = null) {
  return restCallBuilderService({
    requestRoute: `/upload?d=${deal}&format=image`,
    init: {
      method: 'POST',
      body: files,
      credentials: 'include',
    },
  });
}

function servicePostUploadedDocuments$(deal, files = null) {
  return restCallBuilderService({
    requestRoute: `/upload?d=${deal}&format=document`,
    init: {
      method: 'POST',
      body: files,
      credentials: 'include',
    },
  });
}

export { servicePostUploadedImage$, servicePostUploadedDocuments$ };
