import React from 'react';
import PropTypes from 'prop-types';

import HEYGFooterMinimal from '.././HEYGFooterMinimal/HEYGFooterMinimal';
import HEYGHeaderMinimal from '.././HEYGHeaderMinimal/HEYGHeaderMinimal';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * @returns {JSX.Element} - The rendered page layout 1 component.
 */
function PageLayout1({ children }) {
    return (
        <div className="max-w-[990px] mx-auto mb-4 px-4">
            <HEYGHeaderMinimal />
            {children}
            <HEYGFooterMinimal />
        </div>
    );
}

PageLayout1.propTypes = {
    children: PropTypes.element.isRequired
};

export default PageLayout1;
