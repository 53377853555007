import { REST_NEW_LEAD } from '../../../@global/routes';
import restCallBuilderService from '../../../@global/rest-call-builder/rest-call-builder.service';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
function servicePostFilledConfig$(config = null) {
  return restCallBuilderService({
    requestRoute: REST_NEW_LEAD,
    init: {
      method: 'POST',
      body: JSON.stringify(config),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

export default servicePostFilledConfig$;
