/**
 * Creates the contact object for form submission
 * @param {object} submitConfig - Form data from the ConfiguratorChoices components
 * @returns {object} The contact object
*/
const createContactObject = (submitConfig) => ({
    contact: {
        email: submitConfig.lead_mail,
        phone: submitConfig.lead_tel,
        firstName: submitConfig.lead_first_name,
        lastName: submitConfig.lead_second_name,
        fieldValues: [
            {
                field: '27', // type
                value: 'Interessent/ Kunde',
            },
            {
                field: '3', // type
                value: submitConfig.lead_street_and_number,
            },
            {
                field: '2', // type
                value: submitConfig.lead_district,
            },
            {
                field: '1', // type
                value: submitConfig.lead_plz,
            },
        ],
    },
});

/**
 * Creates the deal object for form submission
 * @param {object} cfg - Configuration data from the ConfiguratorChoices components
 * @param {string} leadRoofArea - Lead roof area from URL query parameters
 * @param {object} submitConfig - Form data from the ConfiguratorChoices components
 * @returns {object} The deal object
*/
const createDealObject = (cfg, leadRoofArea, submitConfig) => {
    const title = `DIY - ${submitConfig.lead_plz} - ${leadRoofArea} m²`;
    const conditionalDescription = submitConfig.lead_comment ? 'Kunden-Anmerkung beachten' : 'Keine Kunden-Anmerkung';

    return {
        deal: {
            contact: undefined, // is set through backend.
            title: title,
            description: conditionalDescription,
            group: '1',
            stage: '1',
            currency: 'eur',
            value: 0,
            owner: '1',
            status: 0,
            percent: null,
            fields: [
                {
                    customFieldId: 67, // type
                    fieldValue: leadRoofArea,
                },
                {
                    customFieldId: 3, // type
                    fieldValue: cfg.roof_age,
                },
                {
                    customFieldId: 18, // type
                    fieldValue: cfg.has_roof_load,
                },
                {
                    customFieldId: 21, // type
                    fieldValue: cfg.roof_type ?? '',
                },
                {
                    customFieldId: 66, // type
                    fieldValue: cfg.roof_wood ?? '',
                },
                {
                    customFieldId: 7, // type
                    fieldValue: submitConfig.lead_street_and_number,
                },
                {
                    customFieldId: 10, // type
                    fieldValue: submitConfig.lead_district,
                },
                {
                    customFieldId: 9, // type
                    fieldValue: submitConfig.lead_plz,
                },
                {
                    customFieldId: 63, // type
                    fieldValue: submitConfig.lead_comment ?? '',
                },
                {
                    customFieldId: 65, // type
                    fieldValue: submitConfig.lead_accept_legal,
                },
            ],
        }
    };
}

export { createDealObject, createContactObject };