/**
 * @author Louis Born <louis.bron@hey-gruen.de>
 * @type {{doubleGarage: number, singleGarage: number, familyHouse: number}}
 */
const AverageRoofSizes = {
  singleGarage: 18,
  doubleGarage: 36,
  familyHouse: 100,
};

Object.freeze(AverageRoofSizes);

export default AverageRoofSizes;
