import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * HEYGText Component represents an input field with validation for form input.
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.id - The unique ID for the input element.
 * @param {string} props.type - The type of input (e.g., text, number, email).
 * @param {string} props.name - The name attribute for the input element.
 * @param {string} props.label - The label text for the input field.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {RegExp} props.regValidation - The regular expression pattern for input validation.
 * @param {boolean} props.required - Whether the input is required or not.
 * @param {string} props.value - The initial value for the input field.
 * @param {string} props.errorMessage - The error message to display when input is invalid.
 * @returns {JSX.Element} - The rendered HEYGText components.
 */
function HEYGText({
  id,
  type,
  required,
  name,
  label,
  placeholder,
  regValidation,
  value,
}) {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);

  const classNamesInput = `w-full h-[56px] heyg-text-base hover:bg-neutral-200 rounded-[4px] border-b ${error ? 'border-HEYG-error' : 'border-gray-300'} bg-neutral-100 text-neutral-900 focus:outline-none focus:border-HEYG-accent placeholder-neutral-400 px-2 py-2`;
  const classNamesLabel = `ml-2 mb-[2px] heyg-text-xs font-bold ${error ? 'text-HEYG-error' : 'text-HEYG-black'}`;

  /**
     * Handles the input change event and performs validation using the specified regex pattern.
     * Updates the input value and error state accordingly.
     * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event object.
     */
  const validateInputChangeUsingRegex = (e) => {
    setInputValue(e.target.value);
    const isValid = (e.target.value.match(regValidation) == null);
    setError(isValid);
  };

  // Update the input value when the 'value' prop changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <div className="w-full relative">
      <label htmlFor={id} className={classNamesLabel}>
        {label}
        {required && <span className="text-HEYG-error"> *</span>}
      </label>
      <input
        id={id}
        type={type}
        className={classNamesInput}
        name={name}
        placeholder={placeholder}
        onInput={validateInputChangeUsingRegex}
        required={required}
        value={inputValue}
        disabled={value !== ''}
      />
      <span id={`error-${id}`} className="absolute block heyg-text-xs font-bold -bottom-4 text-HEYG-error" />
    </div>
  );
}

HEYGText.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  regValidation: PropTypes.string,
  value: PropTypes.string,
};

HEYGText.defaultProps = {
  required: true,
  placeholder: '',
  regValidation: '',
  value: '',
};

export default HEYGText;
