import React, { createContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { INTERN_CONFIGURATOR_START, INTERN_ACCOUNT_BASE_WITHOUT_ID } from "../routes";
import { Subject } from "rxjs";

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({ children }) => {
    let [user, setUser] = useState(() => localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);

    const navigate = useNavigate();
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [tokenExpirationSubject] = useState(() => new Subject());

    useEffect(() => {
        const subscription = tokenExpirationSubject.subscribe(() => {
            setIsTokenExpired(true);
        });

        return () => {
            subscription.unsubscribe();
        }
    }, [tokenExpirationSubject]);

    const resetTokenExpired = () => {
        setIsTokenExpired(false);
    }

    const loginUser = async (id) => {
        try {
            let response = await fetch(process.env.REACT_APP_REST_DOMAIN + '/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ 'id': id })
            })
    
            if (response.status === 200) {
                const data = await response.json();
                setUser(data);
                resetTokenExpired(false);
                localStorage.setItem('user', JSON.stringify(data));
                navigate(INTERN_ACCOUNT_BASE_WITHOUT_ID + '/' + id);
            } else {
                throw new Error('Invalid');
            }
        } catch (error) {
            navigate(INTERN_CONFIGURATOR_START);
        }
    }


    const logoutUser = () => {
        setUser(null)
        localStorage.removeItem('user')
        navigate(INTERN_CONFIGURATOR_START);
    }

    let contextData = {
        user: user,
        setUser: setUser,
        loginUser: loginUser,
        logoutUser: logoutUser,
        isTokenExpired: isTokenExpired,
        tokenExpirationSubject: tokenExpirationSubject
    }

    return (
        <AuthContext.Provider value={contextData} >
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.element,
};