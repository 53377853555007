import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function LeadAppNavigation({ actionLeft, middleChild, actionRight }) {
    const navigate = useNavigate();

    if (!actionLeft) {
        actionLeft = (
            <a onClick={() => navigate(-1)} className='heyg-btn-secondary heyg-btn--min-space' type='button'>Zurück</a>
        );
    }

    return (
        <div className="px-8 pb-4 sm:mx-28 mt-24">
            <div className="relative">
                <div
                    className="fixed bottom-0 mt-8 left-0 right-0 py-3 md:py-4 bg-white shadow-md z-40"
                >
                    <div className="max-w-[990px] mx-auto px-4 sm:px-6">
                        <div className="flex justify-start sm:justify-between items-center">
                            <div className="w-2/6 sm:w-1/3">
                                {actionLeft}
                            </div>
                            <div
                                className="invisible sm:visible w-2/6 text-center"
                            >
                                {middleChild}
                            </div>
                            <div className="w-4/6 sm:w-1/3 flex justify-end">
                                {actionRight}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

LeadAppNavigation.propTypes = {
    actionLeft: PropTypes.element,
    middleChild: PropTypes.element,
    actionRight: PropTypes.element
};

export default LeadAppNavigation;