import { INTERN_CONFIGURATOR_START, EXTERN_SUBMIT_CONFIRMATION } from './routes';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
const redirectToStart = () => {
  window.location.href = `${INTERN_CONFIGURATOR_START}`;
};

const redirectToConfirmation = () => {
  window.location.href = `${EXTERN_SUBMIT_CONFIRMATION}`;
}

export { redirectToStart, redirectToConfirmation };
