import React from 'react';
import {
  EXTERN_DATA_PRIVACY, EXTERN_HELP_AND_FAQ, EXTERN_IMPRINT, EXTERN_HOMEPAGE,
} from '../../@global/routes';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * @returns {JSX.Element} - The rendered minimal Footer components.
 */
function HEYGFooterMinimal() {
  const LINKS = [
    { href: EXTERN_HOMEPAGE, label: 'Startseite' },
    { href: EXTERN_IMPRINT, label: 'Impressum' },
    { href: EXTERN_DATA_PRIVACY, label: 'Datenschutz' },
    { href: EXTERN_HELP_AND_FAQ, label: 'Hilfe & FAQs' },
  ];

  const renderFooterNavigationItems = () => (
    // eslint-disable-next-line react/jsx-filename-extension
    <ul className="flex flex-wrap justify-center gap-2">
      {LINKS.map((element, index) => (
        <li key={element.label}>
          <a href={element.href} className="footer-link">{element.label}</a>
          {(LINKS.length - 1) !== index && <span>{' | '}</span>}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="max-w-screen-2xl mt-20 mx-auto px-4">
      <div className="flex flex-col justify-center items-center gap-4 text-center">
        <h3>heygrün</h3>
        <p>Die heygrün-Dienste werden betrieben von der heygrün GmbH.</p>
        {renderFooterNavigationItems()}
      </div>
    </div>
  );
}

export default HEYGFooterMinimal;
