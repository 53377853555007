import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { redirectToConfirmation, redirectToStart } from '../../../../@global/redirection';
import { decodeFromUrlQueryString } from '../../../../@global/encoder';

import PageLayout2 from '../../../../@ui-common/PageLayout2/PageLayout2';

import ConfiguratorChoices from './configurator-choices/configurator-choices';
import ConfiguratorBenefitDisplay from './configurator-benefit-display/configurator-benefit-display';
import postFilledConfig$ from '../../services/configurator-service';
import { createDealObject, createContactObject } from '../../services/lead-service';

function PageConfigurator({ process, callback }) {
  const [leadRoofSize, setLeadRoofSize] = useState(0);

  /**
     * Retrieves the lead roof area from the URL query parameters.
     * If the roof area is not available, it redirects to the landing page.
     * @returns {string} The lead roof area
     */
  const retrieveLeadServiceArea = () => {
    const queryString = window.location.search;
    const decodedUrlParams = decodeFromUrlQueryString(queryString);
    const leadRoofArea = decodedUrlParams.get('roof-size');

    if (leadRoofArea == null || leadRoofArea === '') redirectToStart();

    return leadRoofArea;
  };

  /**
     * Handles the form submission of the ConfiguratorChoices components
     * @param {object} cfg - Configuration data from the ConfiguratorChoices components
     * @param {object} submitConfig - Form data from the ConfiguratorChoices components
     */
  const onSubmitSteps = (cfg, submitConfig) => {
    callback({ isPending: true, submitted: false, hasError: false });

    const submission = {
      lead_deal_obj: createDealObject(cfg, leadRoofSize, submitConfig),
      lead_contact_obj: createContactObject(submitConfig),
    };

    postFilledConfig$(submission).subscribe((res) => {
      callback({ isPending: false, submitted: true, hasError: (res.error || !(res.ok)) });
      if (!(res.error) || res.ok) {
        redirectToConfirmation();
      }
    });
  };

  useEffect(() => {
    const roofSize = retrieveLeadServiceArea();
    setLeadRoofSize(roofSize);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <PageLayout2 banner={<ConfiguratorBenefitDisplay />}>
      <ConfiguratorChoices processError={process.hasError} onSubmitCfg={onSubmitSteps} />
    </PageLayout2>
  );
}

PageConfigurator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  process: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

export default PageConfigurator;
