import React from 'react';
import PropTypes from 'prop-types';

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 * HEYGCheckbox Component represents a styled checkbox input.
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.id - The unique ID for the checkbox input.
 * @param {string} props.name - The name attribute for the checkbox input.
 * @param {boolean} props.checked - Whether the checkbox is checked or not.
 * @param {boolean} props.required - Whether the checkbox is required or not.
 * @param {string|JSX.Element} props.content - The content to display next to the checkbox.
 * @returns {JSX.Element} - The rendered HEYGCheckbox components.
 */
function HEYGToggle({
  id, name, content, required, checked = true,
}) {
  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <label htmlFor={id} className="relative inline-flex items-center mr-5 cursor-pointer">
      <input
        id={id}
        type="checkbox"
        name={name}
        className="w-[18px] h-[18px] accent-[#2363d6] cursor-pointer"
        defaultChecked={checked}
        required={required}
      />
      <span className="ml-3">{content}</span>
    </label>
  );
}

HEYGToggle.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  required: PropTypes.bool,
  checked: PropTypes.bool,
};

HEYGToggle.defaultProps = {
  required: true,
  checked: true,
};

export default HEYGToggle;
